




































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class Login extends Vue {
  form = {
    userId: '',
    password: ''
  }
  errorMessage = {
    userId: '学びエイドID、またはパスワードを入力してください',
    loginFailed: 'ログイン認証に失敗しました'
  }
  message: string = ''

  get getMsg () {
    return this.message
  }

  private isValid () {
    return (this.form.userId === '') || (this.form.password === '')
  }

  private async login () {
    if (this.isValid()) {
      this.message = this.errorMessage.userId
      return
    }

    let self = this
    // Entrance-API
    let eParams = new FormData()
    eParams.append('id', this.form.userId)
    eParams.append('pw', this.form.password)

    await axios({
      method: 'POST',
      url: process.env.VUE_APP_ENTRANCE_API + '/app/login2',
      timeout: 10000,
      data: eParams
    })
      .then(response => {
        if (response.status === 200) {
          let eToken = response.data.value.app_token
          let jyukuName = response.data.value.jyuku_name
          let alias = response.data.value.alias
          let companyId = response.data.value.company_id
          let eStatus = response.data.result

          if (eStatus === 'ok' && eToken !== '') {
            this.$store.state.entranceApiToken = eToken
            localStorage.setItem(process.env.VUE_APP_ENTRANCE_TOKEN_KEY, eToken)
            localStorage.setItem(process.env.VUE_APP_JYUKU_NAME_KEY, jyukuName)
            localStorage.setItem(process.env.VUE_APP_JYUKU_ALIAS_KEY, alias)
            localStorage.setItem(process.env.VUE_APP_COMPANY_ID_KEY, companyId)

            if (alias != null && alias.indexOf('tkd-') === 0) {
              self.$store.state.isTakeda = true
            } else {
              self.$store.state.isTakeda = false
              // disabled temp
              localStorage.removeItem(process.env.VUE_APP_SETTING_TEMP_KEY)
            }
            if (alias === 'dummy') {
              self.$store.state.isTakeda = true
            }
            if (companyId === '1049') {
              self.$store.state.isCkc = true
              self.$store.state.isTakeda = false
            }

            if (this.$store.state.isSettingTeacher && !this.$store.state.isSettingStudent) {
              self.$router.push({ name: 'staff' })
            } else {
              self.$router.push({ name: 'entrance' })
            }
          } else {
            this.message = this.errorMessage.loginFailed
          }
        } else {
          this.message = this.errorMessage.loginFailed
        }
      })
      .catch(error => {
        console.log(error)
        this.message = this.errorMessage.loginFailed
      })
  }

  created () {
  }
}
