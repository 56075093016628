






















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Error extends Vue {
}
