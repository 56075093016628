import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import Login from '../views/Login.vue'
import Entrance from '../views/Entrance.vue'
import EntranceQr from '../views/EntranceQr.vue'
import EntranceStaff from '../views/EntranceStaff.vue'
import EntranceStaffQr from '../views/EntranceStaffQr.vue'
import Setting from '../views/Setting.vue'
import Error from '../views/Error.vue'
import Vuex from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: (to: Route, from: Route, next: any) => {
      getToken()
      if (Vuex.state.isLogin) {
        getSettings()
        if (Vuex.state.isSettingTeacher && !Vuex.state.isSettingStudent) {
          next('/staff')
        } else {
          next('/entrance')
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Login,
    beforeEnter: (to: Route, from: Route, next: any) => {
      localStorage.removeItem(process.env.VUE_APP_ENTRANCE_TOKEN_KEY)
      localStorage.removeItem(process.env.VUE_APP_JYUKU_NAME_KEY)
      localStorage.removeItem(process.env.VUE_APP_JYUKU_ALIAS_KEY)
      localStorage.removeItem(process.env.VUE_APP_COMPANY_ID_KEY)
      Vuex.state.isLogin = false
      next()
    }
  },
  {
    path: '/entrance',
    name: 'entrance',
    component: Entrance,
    beforeEnter: (to: Route, from: Route, next: any) => {
      getToken()
      if (Vuex.state.isLogin) {
        getSettings()
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/qr',
    name: 'qr',
    component: EntranceQr,
    beforeEnter: (to: Route, from: Route, next: any) => {
      getToken()
      if (Vuex.state.isLogin) {
        getSettings()
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/staff',
    name: 'staff',
    component: EntranceStaff,
    beforeEnter: (to: Route, from: Route, next: any) => {
      getToken()
      if (Vuex.state.isLogin) {
        getSettings()
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/staffqr',
    name: 'staffqr',
    component: EntranceStaffQr,
    beforeEnter: (to: Route, from: Route, next: any) => {
      getToken()
      if (Vuex.state.isLogin) {
        getSettings()
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    beforeEnter: (to: Route, from: Route, next: any) => {
      getToken()
      if (Vuex.state.isLogin) {
        getSettings()
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '*',
    name: 'error',
    component: Error
  }
]

function getToken () {
  let token = localStorage.getItem(process.env.VUE_APP_ENTRANCE_TOKEN_KEY)
  if (token !== undefined && token !== null && token !== '') {
    Vuex.state.entranceApiToken = token
    Vuex.state.isLogin = true
  } else {
    Vuex.state.isLogin = false
  }
}

function getSettings () {
  let qr = localStorage.getItem(process.env.VUE_APP_SETTING_QRCODE_KEY)
  let temp = localStorage.getItem(process.env.VUE_APP_SETTING_TEMP_KEY)
  let st = localStorage.getItem(process.env.VUE_APP_SETTING_TEACHER_KEY)
  let ss = localStorage.getItem(process.env.VUE_APP_SETTING_STUDENT_KEY)

  if (st === null && ss === null) {
    ss = String(true)
    localStorage.setItem(process.env.VUE_APP_SETTING_STUDENT_KEY, ss)
  }

  if (Vuex.state.isSettingQrCode !== null) {
    Vuex.state.isSettingQrCode = Boolean(qr)
  }
  if (Vuex.state.isSettingTemperature !== null) {
    Vuex.state.isSettingTemperature = Boolean(temp)
  }
  if (Vuex.state.isSettingTeacher !== null) {
    Vuex.state.isSettingTeacher = Boolean(st)
  }
  if (Vuex.state.isSettingStudent !== null) {
    Vuex.state.isSettingStudent = Boolean(ss)
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
