

































































import { Component, Vue } from 'vue-property-decorator'
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

@Component
export default class Entrance extends Vue {
  private onChangeQr () {
    if (this.$store.state.isSettingQrCode) {
      localStorage.setItem(process.env.VUE_APP_SETTING_QRCODE_KEY, String(this.$store.state.isSettingQrCode))
    } else {
      localStorage.setItem(process.env.VUE_APP_SETTING_QRCODE_KEY, '')
    }
  }

  private onChangeTemp () {
    if (this.$store.state.isSettingTemperature) {
      localStorage.setItem(process.env.VUE_APP_SETTING_TEMP_KEY, String(this.$store.state.isSettingTemperature))
    } else {
      localStorage.setItem(process.env.VUE_APP_SETTING_TEMP_KEY, '')
    }
  }

  private onChangeTeacher () {
    if (this.$store.state.isSettingTeacher) {
      localStorage.setItem(process.env.VUE_APP_SETTING_TEACHER_KEY, String(this.$store.state.isSettingTeacher))
    } else {
      localStorage.setItem(process.env.VUE_APP_SETTING_TEACHER_KEY, '')

      this.$store.state.isSettingStudent = true
      localStorage.setItem(process.env.VUE_APP_SETTING_STUDENT_KEY, String(this.$store.state.isSettingStudent))
    }
  }

  private onChangeStudent () {
    if (this.$store.state.isSettingStudent) {
      localStorage.setItem(process.env.VUE_APP_SETTING_STUDENT_KEY, String(this.$store.state.isSettingStudent))
    } else {
      localStorage.setItem(process.env.VUE_APP_SETTING_STUDENT_KEY, '')
    }
  }

  get refs (): any {
    return this.$refs
  }

  created () {
  }

  mounted () {
  }

  beforeDestroy () {
  }
}
