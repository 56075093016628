

























































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class Header extends Vue {
  isOpen: boolean = false
  jyukuName: string | null = ''
  appVersion: string = ''
  cdate: string = ''
  ctime: string = ''
  isRareLogo: boolean = false
  weeks = ['日', '月', '火', '水', '木', '金', '土']

  private logout () {
    let self = this
    this.isOpen = false
    self.$router.push({ name: 'logout' })
  }

  private toEntrance () {
    let self = this
    this.isOpen = false
    if (self.$route.path !== '/entrance') {
      self.$router.push({ name: 'entrance' })
    }
  }

  private toEntranceQr () {
    let self = this
    this.isOpen = false
    if (self.$route.path !== '/qr') {
      self.$router.push({ name: 'qr' })
    }
  }

  private toStaff () {
    let self = this
    this.isOpen = false
    if (self.$route.path !== '/staff') {
      self.$router.push({ name: 'staff' })
    }
  }

  private toSetting () {
    let self = this
    this.isOpen = false
    if (self.$route.path !== '/setting') {
      self.$router.push({ name: 'setting' })
    }
  }

  private randomLogo () {
    this.isRareLogo = false
    let r = Math.floor(Math.random() * 100)
    if (r === 1) {
      this.isRareLogo = true
    }
  }

  private setClock () {
    let now = moment(new Date())
    this.cdate = now.format('MM/DD') + '(' + this.weeks[parseInt(now.format('d'))] + ')'
    this.ctime = now.format('HH:mm:ss')
  }

  private reload () {
    this.$router.go(0)
  }

  get jyukuName1 () {
    return this.jyukuName
  }

  get appVersion1 () {
    return this.appVersion
  }

  get cdate1 () {
    return this.cdate
  }

  get ctime1 () {
    return this.ctime
  }

  get refs (): any {
    return this.$refs
  }

  created () {
    this.jyukuName = localStorage.getItem(process.env.VUE_APP_JYUKU_NAME_KEY)
    this.appVersion = process.env.VUE_APP_NOB_APP_VERSION

    let alias = localStorage.getItem(process.env.VUE_APP_JYUKU_ALIAS_KEY)
    let companyId = localStorage.getItem(process.env.VUE_APP_COMPANY_ID_KEY)

    if (alias != null && alias.indexOf('tkd-') === 0) {
      this.$store.state.isTakeda = true
    } else if (alias === 'dummy') {
      this.$store.state.isTakeda = true
    } else if (companyId === '1049') {
      this.$store.state.isCkc = true
    }

    let self = this
    this.setClock()
    setInterval(function () { self.setClock() }, 1000)

    this.randomLogo()
  }

  updated () {
    this.jyukuName = localStorage.getItem(process.env.VUE_APP_JYUKU_NAME_KEY)
    this.appVersion = process.env.VUE_APP_NOB_APP_VERSION
  }
}
