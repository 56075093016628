
































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios, { AxiosRequestConfig, AxiosProxyConfig, AxiosResponse, AxiosError } from 'axios'
Vue.use(require('vue-qrcode-reader'))

@Component
export default class EntranceStaffQr extends Vue {
  num: string = ''
  uid: string = ''
  uname: string = ''
  camera: string = 'auto'
  isSwitchCamera: boolean = false
  isIn: boolean = false
  inMsg: string = '入室しました'
  outMsg: string = '退室しました'
  status: string = ''
  statusIn: string = 'in'
  statusOut: string = 'out'
  isEntranceResult: boolean = false
  defaultTemperature: string = '30.0'
  temperature: string = this.defaultTemperature
  errorQrMsg: string = ''
  errorMessage = {
    notFound: '先生が見つかりません',
    system: 'システムでエラーが発生しました'
  }

  private clickInit () {
    this.status = ''
    this.isEntranceResult = false
    this.temperature = this.defaultTemperature
  }

  private clickNumTemp (val: string) {
    if (this.uname === '') return

    if (this.temperature.length === 2) {
      this.temperature += '.' + val
    } else if (this.temperature.length >= 4) {
      this.temperature = '3' + val
    }

    this.num = val
    let self = this
    setTimeout(function () {
      self.num = ''
    }, 100)
  }

  private async checkIn () {
    if (await this.updateEntranceStatus(this.statusIn)) {
      this.$swal({
        icon: 'success',
        title: this.inMsg,
        showConfirmButton: false,
        timer: 2000
      })
    } else {
      this.showError()
    }

    let self = this
    setTimeout(function () {
      self.reload()
    }, 2000)
  }

  private async updateEntranceStatus (status: string) : Promise<boolean> {
    if (this.uid === '') {
      return false
    }
    let self = this
    let params = new URLSearchParams()
    params.append('status', status)
    params.append('lid', this.uid)
    params.append('temp', this.temperature)
    params.append('token', self.$store.state.entranceApiToken)

    await axios({
      method: 'POST',
      url: process.env.VUE_APP_ENTRANCE_API + '/set/staff',
      timeout: 10000,
      data: params
    })
      .then(response => {
        if (response.status === 200 && response.data.result === 'ok') {
          self.isEntranceResult = true
        } else {
          self.isEntranceResult = false
        }
      })
      .catch(error => {
        self.isEntranceResult = false
        console.log(error)
      })

    return self.isEntranceResult
  }

  private async onQrDecode (v: string) {
    let self = this

    let us = v.split('?')
    var searchParams = new URLSearchParams(us[1])
    let qp = searchParams.get('p')
    let sig = searchParams.get('s')

    if (qp === null || sig === null) {
      return false
    }

    let params = new URLSearchParams()
    params.append('user', 'p=' + qp)
    params.append('sig', sig)
    params.append('token', self.$store.state.entranceApiToken)

    if (this.$store.state.isSettingTemperature) {
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_ENTRANCE_API + '/get/staffqr',
        timeout: 10000,
        data: params
      })
        .then(response => {
          if (response.status === 200 && response.data.result === 'ok' && Object.keys(response.data.value).length !== 0) {
            this.isIn = response.data.value.checkin
            if (!this.isIn) {
              this.uname = response.data.value.name
              this.uid = response.data.value.eid
            }
          } else {
            this.$swal({
              icon: 'error',
              title: this.errorMessage.notFound,
              showConfirmButton: false,
              timer: 1200
            })
            this.clickInit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showError()
        })
    }

    if (!this.$store.state.isSettingTemperature || this.isIn) {
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_ENTRANCE_API + '/set/staffqr',
        timeout: 10000,
        data: params
      })
        .then(response => {
          if (response.status === 200 && response.data.result === 'ok' && Object.keys(response.data.value).length !== 0) {
            self.status = response.data.value.status
            self.isEntranceResult = true
          } else {
            self.isEntranceResult = false
          }
        })
        .catch(error => {
          self.isEntranceResult = false
          console.log(error)
        })

      if (this.isEntranceResult) {
        this.$swal({
          icon: 'success',
          title: this.status === this.statusIn ? this.inMsg : this.outMsg,
          showConfirmButton: false,
          timer: 2000
        })

        let self = this
        setTimeout(function () {
          self.reload()
        }, 2000)
      } else {
        this.showError()
      }
    }
  }

  private paintOutline (detectedCodes :any, ctx: any) {
    for (const detectedCode of detectedCodes) {
      const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

      ctx.strokeStyle = 'blue'

      ctx.beginPath()
      ctx.moveTo(firstPoint.x, firstPoint.y)
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y)
      }
      ctx.lineTo(firstPoint.x, firstPoint.y)
      ctx.closePath()
      ctx.stroke()
    }
  }

  private switchCamera () {
    switch (this.camera) {
      case 'front':
        this.camera = 'rear'
        break
      case 'rear':
        this.camera = 'front'
        break
    }
  }

  private isMobile () {
    let ut = navigator.userAgent
    let rs = false

    if (ut.indexOf('iPhone') > 0 || ut.indexOf('iPod') > 0 || ut.indexOf('Android') > 0 || ut.indexOf('Mobile') > 0) {
      rs = true
    } else if (ut.indexOf('iPad') > 0 || ut.indexOf('Android') > 0) {
      rs = true
    } else if (ut.indexOf('Mac OS') > 0 && document.ontouchstart !== undefined) {
      rs = true
    }

    return rs
  }

  logErrors (promise: any) {
    promise.catch(console.error)
  }

  private showError (isLogout: boolean = false) {
    this.$swal({
      icon: 'error',
      title: this.errorMessage.system,
      showConfirmButton: false,
      timer: 2000
    })

    if (isLogout) {
      this.$router.push({ name: 'logout' })
    } else {
      this.reload()
    }
  }

  private reload () {
    this.$router.go(0)
  }

  private async onQrInit (promise: any) {
    try {
      await promise
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'NotAllowedError') {
          this.errorQrMsg = 'ERROR: カメラのアクセスが許可されていません'
        } else if (error.name === 'NotFoundError') {
          this.errorQrMsg = 'ERROR: このデバイスにカメラがありません'
        } else if (error.name === 'NotSupportedError') {
          this.errorQrMsg = 'ERROR: secure context required (HTTPS, localhost).'
        } else if (error.name === 'NotReadableError') {
          this.errorQrMsg = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          if (this.camera === 'front') {
            this.camera = 'rear'
          }
          if (this.camera === 'rear') {
            this.camera = 'front'
          }
          this.errorQrMsg = 'ERROR: installed cameras are not suitable.'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.errorQrMsg = 'ERROR: Stream API is not supported in this browser.'
        } else {
          this.errorQrMsg = `ERROR: Camera error (${error.name})`
        }
      }
    } finally {
    }
  }

  public onKeydown (e: any) {
    let keyCode = e.key
    if (Number(keyCode) !== Number.NaN && (Number(keyCode) >= 0 && Number(keyCode) <= 9)) {
      this.clickNumTemp(keyCode)
    } else if (keyCode === 'Enter') {
      this.checkIn()
    } else if (keyCode === 'Escape') {
      this.clickInit()
    }
  }

  private toEntrance () {
    let self = this
    if (self.$route.path !== '/staff') {
      self.$router.push({ name: 'staff' })
    }
  }

  get refs (): any {
    return this.$refs
  }

  created () {
    if (this.isMobile()) {
      this.isSwitchCamera = true
      this.camera = 'front'
    }

    this.clickInit()
  }

  mounted () {
    window.addEventListener('keydown', this.onKeydown, false)
  }

  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown, false)
  }
}
