import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    entranceApiToken: '',
    isSettingQrCode: false,
    isSettingTemperature: false,
    isSettingStudent: true,
    isSettingTeacher: false,
    isTakeda: false,
    isCkc: false
  },
  mutations: {},
  actions: {},
  modules: {},
  plugins: []
})
