


















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios, { AxiosRequestConfig, AxiosProxyConfig, AxiosResponse, AxiosError } from 'axios'

@Component
export default class EntranceStaff extends Vue {
  num: string = ''
  uid: string = ''
  uname: string = ''
  isIn: boolean = false
  inMsg: string = '入室しました'
  outMsg: string = '退室しました'
  statusIn: string = 'in'
  statusOut: string = 'out'
  isEntranceResult: boolean = false
  defaultTemperature: string = '30.0'
  temperature: string = this.defaultTemperature
  errorMessage = {
    notFound: '先生が見つかりません',
    system: 'システムでエラーが発生しました'
  }

  private clickInit () {
    this.uid = ''
    this.uname = ''
    this.isEntranceResult = false
    this.temperature = this.defaultTemperature
  }

  private clickNum (val: string) {
    if (this.uname !== '') return

    this.uid += val
    if (this.$store.state.isTakeda) {
      if (this.uid.length > 9) {
        this.clickInit()
      }
    } else {
      if (this.uid.length > 4) {
        this.clickInit()
      }
    }

    this.num = val
    let self = this
    setTimeout(function () {
      self.num = ''
    }, 100)
  }

  private clickNumTemp (val: string) {
    if (this.uname === '') return

    if (this.temperature.length === 2) {
      this.temperature += '.' + val
    } else if (this.temperature.length >= 4) {
      this.temperature = '3' + val
    }

    this.num = val
    let self = this
    setTimeout(function () {
      self.num = ''
    }, 100)
  }

  private clickEnter () {
    if (this.uid === '') return
    let params = new URLSearchParams()
    params.append('token', this.$store.state.entranceApiToken)
    params.append('lid', this.uid)

    axios({
      method: 'POST',
      url: process.env.VUE_APP_ENTRANCE_API + '/get/staff',
      timeout: 10000,
      data: params
    })
      .then(response => {
        if (response.status === 200 && response.data.result === 'ok' && Object.keys(response.data.value).length !== 0) {
          this.uname = response.data.value.name
          this.isIn = response.data.value.checkin
        } else {
          this.$swal({
            icon: 'error',
            title: this.errorMessage.notFound,
            showConfirmButton: false,
            timer: 1200
          })
          this.clickInit()
        }
      })
      .catch(error => {
        console.log(error)
        this.showError()
      })
  }

  private async checkIn () {
    if (await this.updateEntranceStatus(this.statusIn)) {
      this.$swal({
        icon: 'success',
        title: this.inMsg,
        showConfirmButton: false,
        timer: 2000
      })
    } else {
      this.showError()
    }

    this.clickInit()
  }

  private async checkOut () {
    if (await this.updateEntranceStatus(this.statusOut)) {
      this.$swal({
        icon: 'success',
        title: this.outMsg,
        showConfirmButton: false,
        timer: 2000
      })
    } else {
      this.showError()
    }

    this.clickInit()
  }

  private async updateEntranceStatus (status: string) : Promise<boolean> {
    if (this.uid === '') {
      return false
    }
    let self = this
    let params = new URLSearchParams()
    params.append('status', status)
    params.append('lid', this.uid)
    if (this.$store.state.isSettingTemperature && status === this.statusIn) {
      params.append('temp', this.temperature)
    }
    params.append('token', self.$store.state.entranceApiToken)

    await axios({
      method: 'POST',
      url: process.env.VUE_APP_ENTRANCE_API + '/set/staff',
      timeout: 10000,
      data: params
    })
      .then(response => {
        if (response.status === 200 && response.data.result === 'ok') {
          self.isEntranceResult = true
        } else {
          self.isEntranceResult = false
        }
      })
      .catch(error => {
        self.isEntranceResult = false
        console.log(error)
      })

    return self.isEntranceResult
  }

  logErrors (promise: any) {
    promise.catch(console.error)
  }

  private showError (isLogout: boolean = false) {
    this.$swal({
      icon: 'error',
      title: this.errorMessage.system,
      showConfirmButton: false,
      timer: 2000
    })

    if (isLogout) {
      this.$router.push({ name: 'logout' })
    } else {
      this.reload()
    }
  }

  private reload () {
    this.$router.go(0)
  }

  public onKeydown (e: any) {
    let keyCode = e.key
    if (Number(keyCode) !== Number.NaN && (Number(keyCode) >= 0 && Number(keyCode) <= 9)) {
      if (this.uname === '') {
        this.clickNum(keyCode)
      } else {
        this.clickNumTemp(keyCode)
      }
    } else if (keyCode === 'Enter') {
      if (this.uname === '') {
        this.clickEnter()
      } else {
        if (this.isIn) {
          this.checkOut()
        } else {
          this.checkIn()
        }
      }
    } else if (keyCode === 'Backspace') {
      if (this.uid.length > 0 && this.uname === '') {
        let uid = this.uid
        this.uid = uid.slice(0, -1)
      }
    } else if (keyCode === 'Escape' || keyCode === 'Delete') {
      this.clickInit()
    }
  }

  private toEntranceQr () {
    let self = this
    if (self.$route.path !== '/staffqr') {
      self.$router.push({ name: 'staffqr' })
    }
  }

  get refs (): any {
    return this.$refs
  }

  created () {
    this.clickInit()
  }

  mounted () {
    window.addEventListener('keydown', this.onKeydown, false)
  }

  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown, false)
  }
}
